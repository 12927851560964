import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTachometerAlt, FaCogs, FaUserAlt, FaSignOutAlt, FaBoxes, FaLayerGroup, FaShoppingCart, FaBars, FaUser } from 'react-icons/fa';
import './AdminSidebar.css';
import logo from './logo.png';

const AdminSidebar = ({ collapsed, setCollapsed, isMobile }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {isMobile && (
                <div className="mobile-top-bar">
                    <button className="sidebar-toggle" onClick={toggleSidebar}>
                        <FaBars />
                    </button>
                    <div className="user-profile">
                        <FaUser />
                    </div>
                </div>
            )}
            <div className={`admin-sidebar ${isMobile ? 'mobile' : ''} ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-logo">
                    <NavLink to="/">
                        <img src={logo} alt="Logo" />
                    </NavLink>
                </div>
                <div className="sidebar-links">
                    <NavLink to="/admin/dashboard" className="sidebar-link" activeclassname="active">
                        <FaTachometerAlt /> <span>Dashboard</span>
                    </NavLink>
                    <NavLink to="/admin/products" className="sidebar-link" activeclassname="active">
                        <FaBoxes /> <span>Ürünler</span>
                    </NavLink>
                    <NavLink to="/admin/customers" className="sidebar-link" activeclassname="active">
                        <FaUserAlt /> <span>Müşteriler</span>
                    </NavLink>
                    <NavLink to="/admin/collections" className="sidebar-link" activeclassname="active">
                        <FaLayerGroup /> <span>Koleksiyonlar</span>
                    </NavLink>
                    <NavLink to="/admin/orders" className="sidebar-link" activeclassname="active">
                        <FaShoppingCart /> <span>Siparişler</span>
                    </NavLink>
                    <NavLink to="/admin/add-banner" className="sidebar-link" activeclassname="active">
                        <FaCogs /> <span>Banner Ekle</span>
                    </NavLink>
                </div>
                <div className="sidebar-footer">
                    <div className="sidebar-link" onClick={handleLogout}>
                        <FaSignOutAlt /> <span>Çıkış Yap</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminSidebar;
