import React, { useState, useEffect } from 'react';
import ProductCard from '../components/ProductCard';
import SimilarProducts from '../components/SimilarProducts';
import { Container, Row, Col } from 'react-bootstrap';
import './FavoritesPage.css'; // Yeni bir CSS dosyası oluşturacağız
import axios from 'axios';

const FavoritesPage = () => {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('Token bulunamadı, lütfen giriş yapın.');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setFavorites(response.data);
                } else {
                    throw new Error('Favoriler yüklenemedi');
                }
            } catch (error) {
                console.error('Favoriler yüklenirken hata oluştu:', error);
            }
        };

        fetchFavorites();
    }, []);

    const handleFavoriteChange = (productId, isFavorite) => {
        if (!isFavorite) {
            setFavorites(prevFavorites => prevFavorites.filter(fav => fav._id !== productId));
        }
    };

    return (
        <Container fluid className="favorites-container">
            <div className="favorites-header">
                <h1 className="favorites-title">Favorilerim</h1>
                <div className="favorites-underline"></div>
            </div>
            <Row className="favorites-grid">
                {favorites.map(product => (
                    <Col key={product._id} xs={12} sm={6} md={4} lg={3} className="favorite-item">
                        <ProductCard 
                            product={product} 
                            onFavoriteChange={handleFavoriteChange}
                        />
                    </Col>
                ))}
            </Row>
            {favorites.length > 0 && (
                <SimilarProducts 
                    currentProductIds={favorites.map(fav => fav._id)}
                    currentProductNames={favorites.map(fav => fav.name)}
                    currentProductDescriptions={favorites.map(fav => fav.description)}
                />
            )}
        </Container>
    );
};

export default FavoritesPage;
