import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './AuthForms.css';

const SignupForm = ({ setAuth, setAdmin }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Şifreler eşleşmiyor");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, { name, email, password });
            if (res.data.token) {
                localStorage.setItem('token', res.data.token);
                setAuth(true);
                const decoded = JSON.parse(atob(res.data.token.split('.')[1]));
                setAdmin(decoded.isAdmin || false);
                navigate('/');
            } else {
                alert("Kayıt başarılı ancak token alınamadı. Lütfen giriş yapın.");
                navigate('/login');
            }
        } catch (err) {
            console.error('Kayıt hatası:', err);
            if (err.response && err.response.data) {
                alert(`Kayıt hatası: ${err.response.data.message || 'Bilinmeyen bir hata oluştu'}`);
            } else if (err.request) {
                alert('Sunucuya bağlanılamadı. Lütfen internet bağlantınızı kontrol edin.');
            } else {
                alert('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
            }
        }
    };

    return (
        <div className="auth-container">
            <h2>Üye Ol</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Ad</label>
                    <input type="text" placeholder="Ad" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Email adresi</label>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Şifre</label>
                    <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Şifreyi tekrar girin</label>
                    <input type="password" placeholder="Şifreyi tekrar girin" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <button type="submit" className="auth-button">Üye Ol</button>
            </form>
            <p className="auth-link">
                Zaten hesabınız var mı? <Link to="/login">Giriş yapın</Link>
            </p>
        </div>
    );
};

export default SignupForm;
