import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';
import { useAppContext } from '../contexts/AppContext';
import { FaUser, FaShoppingBag, FaAddressCard, FaCreditCard, FaSignOutAlt } from 'react-icons/fa';

const UserProfile = () => {
    const { setAuth, setAdmin } = useAppContext();
    const [userInfo, setUserInfo] = useState(null);
    const [userOrders, setUserOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('profile');
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [editedInfo, setEditedInfo] = useState({ firstName: '', lastName: '', phoneNumber: '', gender: '' });
    const [addresses, setAddresses] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [newAddress, setNewAddress] = useState({ street: '', city: '', country: '', postalCode: '' });
    const [newPaymentMethod, setNewPaymentMethod] = useState({ cardNumber: '', expiryDate: '', cvv: '' });

    const handleEditProfile = () => {
        setIsEditing(true);
        setEditedInfo({
          firstName: userInfo.firstName || '',
          lastName: userInfo.lastName || '',
          phoneNumber: userInfo.phoneNumber || '',
          gender: userInfo.gender || ''
        });
      };

    useEffect(() => {
        fetchUserInfo();
        fetchUserOrders();
        fetchAddresses();
        fetchPaymentMethods();
    }, []);

    const fetchAddresses = async () => {
        try {
          const token = localStorage.getItem('token');
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/addresses`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setAddresses(res.data);
        } catch (error) {
          console.error('Adresler alınırken hata:', error);
        }
      };

      const handleAddAddress = async (e) => {
        e.preventDefault();
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/addresses`,
            newAddress,
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          setAddresses([...addresses, response.data]);
          setNewAddress({ street: '', city: '', country: '', postalCode: '' });
          alert('Adres başarıyla eklendi.');
        } catch (error) {
            console.error('Adres eklenirken hata:', error);
          alert('Adres eklenirken bir hata oluştu. Lütfen tekrar deneyin.');
        }
      };


    const fetchPaymentMethods = async () => {
        try {
          const token = localStorage.getItem('token');
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/payment-methods`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setPaymentMethods(res.data);
        } catch (error) {
          console.error('Ödeme yöntemleri alınırken hata:', error);
        }
      };

      const formatCardNumber = (value) => {
        const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        const matches = v.match(/\d{4,16}/g);
        const match = matches && matches[0] || '';
        const parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
          parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
          return parts.join(' ');
        } else {
          return value;
        }
      };

      const formatExpiryDate = (value) => {
        const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        if (v.length >= 2) {
          return v.slice(0, 2) + '/' + v.slice(2, 4);
        }
        return v;
      };

      const handleAddPaymentMethod = async (e) => {
        e.preventDefault();
        try {
          const token = localStorage.getItem('token');
          const formattedCardNumber = newPaymentMethod.cardNumber.replace(/\s/g, '');
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/payment-methods`,
            { ...newPaymentMethod, cardNumber: formattedCardNumber },
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          setPaymentMethods([...paymentMethods, response.data]);
          setNewPaymentMethod({ cardNumber: '', expiryDate: '', cvv: '' });
          alert('Ödeme yöntemi başarıyla eklendi.');
        } catch (error) {
          console.error('Ödeme yöntemi eklenirken hata:', error);
          alert('Ödeme yöntemi eklenirken bir hata oluştu.');
        }
      };
      const handleSaveProfile = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/users/profile`,
            editedInfo,
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          setUserInfo(response.data);
          setIsEditing(false);
          fetchUserInfo();
        } catch (error) {
          console.error('Profil güncellenirken hata:', error);
          alert('Profil güncellenirken bir hata oluştu.');
        }
      };
      const handleInputChange = (e) => {
        setEditedInfo({ ...editedInfo, [e.target.name]: e.target.value });
      };

      const fetchUserInfo = async () => {
        try {
          const token = localStorage.getItem('token');
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/profile`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setUserInfo(res.data);
          setEditedInfo({
            firstName: res.data.firstName || '',
            lastName: res.data.lastName || '',
            phoneNumber: res.data.phoneNumber || '',
            gender: res.data.gender || 'other'
          });
        } catch (error) {
          console.error('Kullanıcı bilgileri alınırken hata:', error);
        }
      };


    const fetchUserOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/user`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserOrders(res.data);
        } catch (error) {
            console.error('Siparişler alınırken hata:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setAuth(false);
        setAdmin(false);
        navigate('/login');
    };

    const handleEditAddress = async (index) => {
        try {
          const token = localStorage.getItem('token');
          const updatedAddress = { ...addresses[index], isEditing: true };
          await axios.put(`${process.env.REACT_APP_API_URL}/users/addresses/${addresses[index]._id}`, updatedAddress, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchAddresses();
        } catch (error) {
          console.error('Adres düzenlenirken hata:', error);
          alert('Adres düzenlenirken bir hata oluştu.');
        }
      };

    const handleDeleteAddress = async (index) => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${process.env.REACT_APP_API_URL}/users/addresses/${addresses[index]._id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const updatedAddresses = addresses.filter((_, i) => i !== index);
          setAddresses(updatedAddresses);
          alert('Adres başarıyla silindi.');
        } catch (error) {
          console.error('Adres silinirken hata:', error);
          alert('Adres silinirken bir hata oluştu.');
        }
      };

    const handleEditPaymentMethod = async (index) => {
        try {
          const token = localStorage.getItem('token');
          const updatedPaymentMethod = { ...paymentMethods[index], isEditing: true };
          await axios.put(`${process.env.REACT_APP_API_URL}/users/payment-methods/${paymentMethods[index]._id}`, updatedPaymentMethod, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchPaymentMethods();
        } catch (error) {
          console.error('Ödeme yöntemi düzenlenirken hata:', error);
          alert('Ödeme yöntemi düzenlenirken bir hata oluştu.');
        }
      };

    const handleDeletePaymentMethod = async (index) => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${process.env.REACT_APP_API_URL}/users/payment-methods/${paymentMethods[index]._id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const updatedPaymentMethods = paymentMethods.filter((_, i) => i !== index);
          setPaymentMethods(updatedPaymentMethods);
          alert('Ödeme yöntemi başarıyla silindi.');
        } catch (error) {
          console.error('Ödeme yöntemi silinirken hata:', error);
          alert('Ödeme yöntemi silinirken bir hata oluştu.');
        }
      };

    const getAvatarName = () => {
      return userInfo.firstName && userInfo.lastName 
        ? `${userInfo.firstName} ${userInfo.lastName}`
        : userInfo.email;
    };

    if (!userInfo) return <div className="loading">Yükleniyor...</div>;

    return (
        <div className="user-profile-container">
            <div className="user-profile-header">
                <h2>Kullanıcı Profili</h2>
                <button onClick={handleLogout}>Çıkış Yap</button>
            </div>
            <div className="user-profile-tabs">
                <button className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`} onClick={() => setActiveTab('profile')}>Profil</button>
                <button className={`tab-button ${activeTab === 'orders' ? 'active' : ''}`} onClick={() => setActiveTab('orders')}>Siparişler</button>
                <button className={`tab-button ${activeTab === 'addresses' ? 'active' : ''}`} onClick={() => setActiveTab('addresses')}>Adresler</button>
                <button className={`tab-button ${activeTab === 'payment' ? 'active' : ''}`} onClick={() => setActiveTab('payment')}>Ödeme Yöntemleri</button>
            </div>
            <div className="user-profile-content">
                {/* Profil içeriği */}
                {activeTab === 'profile' && (
                    <div className="profile-info">
                        <h3>Profil Bilgileri</h3>
                        {isEditing ? (
                            <div className="profile-edit-form">
                                <input
                                    name="firstName"
                                    value={editedInfo.firstName || ''}
                                    onChange={handleInputChange}
                                    placeholder="Ad"
                                />
                                <input
                                    name="lastName"
                                    value={editedInfo.lastName || ''}
                                    onChange={handleInputChange}
                                    placeholder="Soyad"
                                />
                                <input
                                    name="phoneNumber"
                                    value={editedInfo.phoneNumber || ''}
                                    onChange={handleInputChange}
                                    placeholder="Telefon Numarası"
                                />
                                <select
                                    name="gender"
                                    value={editedInfo.gender}
                                    onChange={handleInputChange}
                                >
                                    <option value="male">Erkek</option>
                                    <option value="female">Kadın</option>
                                    <option value="other">Diğer</option>
                                </select>
                                <button onClick={handleSaveProfile}>Kaydet</button>
                                <button onClick={() => setIsEditing(false)}>İptal</button>
                            </div>
                        ) : (
                            <div className="profile-info">
                                <p>Ad: {userInfo.firstName || ''}</p>
                                <p>Soyad: {userInfo.lastName || ''}</p>
                                <p>Telefon: {userInfo.phoneNumber || ''}</p>
                                <p>Cinsiyet: {userInfo.gender === 'male' ? 'Erkek' : userInfo.gender === 'female' ? 'Kadın' : 'Diğer'}</p>
                                <button onClick={handleEditProfile}>Düzenle</button>
                            </div>
                        )}
                    </div>
                )}
                {/* Siparişler içeriği */}
                {activeTab === 'orders' && (
                    <div className="user-orders">
                        <h3>Siparişlerim</h3>
                        {userOrders.length > 0 ? (
                            <table className="order-table">
                                <thead>
                                    <tr>
                                        <th>Sipariş ID</th>
                                        <th>Tarih</th>
                                        <th>Toplam Tutar</th>
                                        <th>Durum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userOrders.map((order) => (
                                        <tr key={order._id} onClick={() => navigate(`/orders/${order._id}`)}>
                                            <td>{order._id}</td>
                                            <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                            <td>{order.totalAmount} TL</td>
                                            <td>{order.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>Henüz bir sipariş bulunmamaktadır.</p>
                        )}
                    </div>
                )}
                {/* Adresler içeriği */}
                {activeTab === 'addresses' && (
                    <div className="user-addresses">
                        <h3>Adreslerim</h3>
                        {addresses.length > 0 ? (
                            <div className="address-list">
                                {addresses.map((address, index) => (
                                    <div key={index} className="address-item">
                                        <p>{address.street}, {address.city}, {address.country}, {address.postalCode}</p>
                                        <div className="button-group">
                                            <button onClick={() => handleEditAddress(index)} className="button button-secondary">Düzenle</button>
                                            <button onClick={() => handleDeleteAddress(index)} className="button button-primary">Sil</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Henüz kayıtlı adresiniz bulunmamaktadır.</p>
                        )}
                        <div className="form-container">
                            <h4 className="form-title">Yeni Adres Ekle</h4>
                            <form onSubmit={handleAddAddress}>
                                <div className="form-group">
                                    <label htmlFor="street">Sokak</label>
                                    <input
                                        id="street"
                                        name="street"
                                        value={newAddress.street}
                                        onChange={(e) => setNewAddress({...newAddress, street: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">Şehir</label>
                                    <input
                                        id="city"
                                        name="city"
                                        value={newAddress.city}
                                        onChange={(e) => setNewAddress({...newAddress, city: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="country">Ülke</label>
                                    <input
                                        id="country"
                                        name="country"
                                        value={newAddress.country}
                                        onChange={(e) => setNewAddress({...newAddress, country: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="postalCode">Posta Kodu</label>
                                    <input
                                        id="postalCode"
                                        name="postalCode"
                                        value={newAddress.postalCode}
                                        onChange={(e) => setNewAddress({...newAddress, postalCode: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="button-group">
                                    <button type="submit" className="button button-primary">Adres Ekle</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {/* Ödeme yöntemleri içeriği */}
                {activeTab === 'payment' && (
                    <div className="user-payment-methods">
                        <h3>Ödeme Yöntemlerim</h3>
                        {paymentMethods.length > 0 ? (
                            <div className="payment-method-list">
                                {paymentMethods.map((method, index) => (
                                    <div key={index} className="payment-method-item">
                                        <p>Kart No: **** **** **** {method.cardNumber.slice(-4)}</p>
                                        <p>Son Kullanma Tarihi: {method.expiryDate}</p>
                                        <div className="button-group">
                                            <button onClick={() => handleEditPaymentMethod(index)} className="button button-secondary">Düzenle</button>
                                            <button onClick={() => handleDeletePaymentMethod(index)} className="button button-primary">Sil</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Henüz kayıtlı ödeme yönteminiz bulunmamaktadır.</p>
                        )}
                        <div className="form-container">
                            <h4 className="form-title">Yeni Ödeme Yöntemi Ekle</h4>
                            <form onSubmit={handleAddPaymentMethod}>
                                <div className="form-group">
                                    <label htmlFor="cardNumber">Kart Numarası</label>
                                    <input
                                        id="cardNumber"
                                        name="cardNumber"
                                        value={newPaymentMethod.cardNumber}
                                        onChange={(e) => setNewPaymentMethod({...newPaymentMethod, cardNumber: formatCardNumber(e.target.value)})}
                                        maxLength="19"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expiryDate">Son Kullanma Tarihi (AA/YY)</label>
                                    <input
                                        id="expiryDate"
                                        name="expiryDate"
                                        value={newPaymentMethod.expiryDate}
                                        onChange={(e) => setNewPaymentMethod({...newPaymentMethod, expiryDate: formatExpiryDate(e.target.value)})}
                                        maxLength="5"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cvv">CVV</label>
                                    <input
                                        id="cvv"
                                        name="cvv"
                                        value={newPaymentMethod.cvv}
                                        onChange={(e) => setNewPaymentMethod({...newPaymentMethod, cvv: e.target.value.replace(/\D/g, '')})}
                                        maxLength="3"
                                        required
                                    />
                                </div>
                                <div className="button-group">
                                    <button type="submit" className="button button-primary">Ödeme Yöntemi Ekle</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserProfile;
        