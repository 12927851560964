import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import './Carousel.css';

const CustomCarousel = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/banners`);
            setBanners(response.data);
        } catch (err) {
            setError('Error fetching banners');
            console.error('Error fetching banners:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="carousel-container">
            <Carousel 
                indicators={true}
                prevIcon={<span className="custom-arrow-left"></span>} 
                nextIcon={<span className="custom-arrow-right"></span>}
                interval={4000}
            >
                {banners.map((banner, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100 carousel-image"
                            src={banner.imageUrl}
                            alt={`Slide ${index + 1}`}
                        />
                        <Carousel.Caption>
                            <h3>{banner.title}</h3>
                            <p>{banner.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default CustomCarousel;
