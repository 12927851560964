import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaTrash, FaMinus, FaPlus, FaShoppingCart } from 'react-icons/fa';
import './Cart.css';

const Cart = () => {
  const [cart, setCart] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOrderButtonActive, setIsOrderButtonActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cart`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCart(response.data);
    } catch (error) {
      console.error('Sepet yüklenirken hata:', error);
    }
  };

  const updateQuantity = async (productId, size, newQuantity) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/cart/update`, {
        productId,
        size,
        quantity: newQuantity
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchCart();
    } catch (error) {
      console.error('Ürün miktarı güncellenirken hata:', error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phone = e.target.value;
    setPhoneNumber(phone);
    setIsOrderButtonActive(phone.startsWith('+') && phone.length >= 10);
  };

  const handleCheckout = async () => {
    if (!isOrderButtonActive) return;

    try {
      const token = localStorage.getItem('token');
      const orderData = {
        phoneNumber,
        cartItems: cart.items.filter(item => item.productId).map(item => ({
          productId: item.productId._id,
          size: item.size,
          quantity: item.quantity,
        })),
        totalAmount: calculateTotal(),
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, orderData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 201) {
        await clearCart();
        navigate('/order-confirmation', { state: { orderId: response.data._id } });
      } else {
        alert('Sipariş oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Sipariş oluşturulurken hata:', error);
      alert('Sipariş oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const clearCart = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/cart/clear`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCart({ items: [] });
    } catch (error) {
      console.error('Sepet temizlenirken hata:', error);
    }
  };

  const calculateTotal = () => {
    return cart.items.reduce((total, item) => {
      return total + (item.productId ? item.productId.price * item.quantity : 0);
    }, 0);
  };

  if (!cart) return <div className="loading">Yükleniyor...</div>;

  return (
    <div className="cart-container">
      <h1 className="cart-title"><FaShoppingCart /> Alışveriş Sepeti</h1>
      {cart.items.length === 0 ? (
        <div className="empty-cart">
          <FaShoppingCart size={50} />
          <p>Sepetinizde ürün bulunmuyor.</p>
          <button onClick={() => navigate('/')}>Alışverişe Başla</button>
        </div>
      ) : (
        <div className="cart-content">
          <div className="cart-items">
            {cart.items.map(item => (
              item.productId && (
                <div className="cart-item" key={`${item.productId._id}-${item.size}`}>
                  <img src={item.productId.mainImageUrl} alt={item.productId.name} className="item-image" />
                  <div className="item-details">
                    <h3 className="item-name">{item.productId.name}</h3>
                    <p className="item-size">Beden: {item.size}</p>
                    <p className="item-price">{item.productId.price} TL</p>
                    <div className="quantity-control">
                      <button 
                        className="quantity-btn" 
                        onClick={() => updateQuantity(item.productId._id, item.size, item.quantity - 1)} 
                        disabled={item.quantity === 1}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button 
                        className="quantity-btn" 
                        onClick={() => updateQuantity(item.productId._id, item.size, item.quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button className="remove-item" onClick={() => updateQuantity(item.productId._id, item.size, 0)}>
                    <FaTrash />
                  </button>
                </div>
              )
            ))}
          </div>
          <div className="cart-summary">
            <h2>Sipariş Özeti</h2>
            <p className="total-amount">Toplam: {calculateTotal().toFixed(2)} TL</p>
            <div className="phone-input">
              <label htmlFor="phoneNumber">Telefon Numarası:</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="+905555555555"
              />
            </div>
            <button 
              className="checkout-button" 
              onClick={handleCheckout} 
              disabled={!isOrderButtonActive}
            >
              Siparişi Tamamla
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
