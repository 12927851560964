import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminAddBanner.css';

const AdminAddBanner = () => {
    const [banner, setBanner] = useState({
        title: '',
        description: '',
        image: null
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setBanner({ ...banner, image: e.target.files[0] });
        } else {
            setBanner({ ...banner, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            };
            const formData = new FormData();
            formData.append('title', banner.title);
            formData.append('description', banner.description);
            formData.append('image', banner.image);

            await axios.post(`${process.env.REACT_APP_API_URL}/banners`, formData, config);
            setBanner({ title: '', description: '', image: null });
        } catch (error) {
            console.error('Error adding banner:', error);
        }
    };

    return (
        <div className="add-banner-container">
            <h2>Banner Ekle</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Başlık</label>
                    <input
                        type="text"
                        name="title"
                        value={banner.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Açıklama</label>
                    <textarea
                        name="description"
                        value={banner.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Resim</label>
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Ekle</button>
            </form>
            <button onClick={() => navigate('/admin/banners')}>Banner Listesi</button> {/* Button for navigating to banner list */}
        </div>
    );
};

export default AdminAddBanner;
