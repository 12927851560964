import React, { useState, useEffect } from 'react';
import { FaMoneyBillWave, FaShoppingCart, FaUsers, FaBoxes, FaLayerGroup } from 'react-icons/fa';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        totalRevenue: 0,
        totalOrders: 0,
        totalCustomers: 0,
        totalProducts: 0,
        totalCollections: 0,
        salesData: [],
        productData: [],
        recentOrders: []
    });

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                console.log('Dashboard Data:', response.data);
                setDashboardData(response.data);
            } catch (error) {
                console.error('Dashboard verisi alınırken hata oluştu:', error.response ? error.response.data : error.message);
                setDashboardData(prevState => ({
                    ...prevState,
                    error: 'Veriler alınamadı. Lütfen daha sonra tekrar deneyin.',
                    totalRevenue: 0,
                    totalOrders: 0,
                    totalCustomers: 0,
                    totalProducts: 0,
                    totalCollections: 0,
                    salesData: [],
                    productData: [],
                    recentOrders: []
                }));
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <div className="dashboard">
            {dashboardData.error && <div className="error-message">{dashboardData.error}</div>}
            <div className="dashboard-header">
                <h1 className="dashboard-title">Yönetim Paneli</h1>
                <p className="dashboard-subtitle">Hoş geldiniz! İşte işletmenizin genel durumu.</p>
            </div>
            <div className="dashboard-cards">
                <div className="card">
                    <FaMoneyBillWave className="card-icon" />
                    <div className="card-content">
                        <h3>Toplam Gelir</h3>
                        <p>₺ {dashboardData.totalRevenue.toFixed(2)}</p>
                    </div>
                </div>
                <div className="card">
                    <FaShoppingCart className="card-icon" />
                    <div className="card-content">
                        <h3>Toplam Sipariş</h3>
                        <p>{dashboardData.totalOrders}</p>
                    </div>
                </div>
                <div className="card">
                    <FaUsers className="card-icon" />
                    <div className="card-content">
                        <h3>Toplam Müşteri</h3>
                        <p>{dashboardData.totalCustomers || 0}</p>
                    </div>
                </div>
                <div className="card">
                    <FaBoxes className="card-icon" />
                    <div className="card-content">
                        <h3>Ürün Sayısı</h3>
                        <p>{dashboardData.totalProducts}</p>
                    </div>
                </div>
                <div className="card">
                    <FaLayerGroup className="card-icon" />
                    <div className="card-content">
                        <h3>Koleksiyon Sayısı</h3>
                        <p>{dashboardData.totalCollections}</p>
                    </div>
                </div>
            </div>
            <div className="dashboard-charts">
                <div className="chart-container">
                    <h3 className="chart-title">Aylık Satışlar</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={dashboardData.salesData}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                            <XAxis dataKey="name" stroke="#333" tick={{fill: '#333'}} allowDecimals={false} />
                            <YAxis stroke="#333" tick={{fill: '#333'}} allowDecimals={false} />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="sales" stroke="#8b0000" strokeWidth={2} dot={{ r: 4 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="chart-container">
                    <h3 className="chart-title">En Çok Satan Ürünler</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={dashboardData.productData}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                            <XAxis dataKey="name" stroke="#333" tick={{fill: '#333'}} allowDecimals={false} />
                            <YAxis stroke="#333" tick={{fill: '#333'}} allowDecimals={false} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="value" fill="#8b0000" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="dashboard-table">
                <h3 className="table-title">Son Siparişler</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Sipariş No</th>
                            <th>Müşteri</th>
                            <th>Tarih</th>
                            <th>Tutar</th>
                            <th>Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardData.recentOrders.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{order.customer}</td>
                                <td>{order.date}</td>
                                <td>₺ {order.amount}</td>
                                <td>
                                    <span className={`status ${order.status ? order.status.toLowerCase() : ''}`}>
                                        {order.status || 'Bilinmiyor'}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
