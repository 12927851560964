import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './AdminAddCollection.css';

const AdminEditCollection = () => {
    const [collection, setCollection] = useState({
        name: '',
        description: '',
        image: null
    });
    const [currentImage, setCurrentImage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCollection();
    }, [id]);

    const fetchCollection = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`);
            setCollection({
                name: res.data.name,
                description: res.data.description,
                image: null
            });
            setCurrentImage(res.data.imageUrl);
        } catch (error) {
            console.error('Koleksiyon getirilirken hata:', error);
        }
    };

    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setCollection({ ...collection, image: e.target.files[0] });
        } else {
            setCollection({ ...collection, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', collection.name);
        formData.append('description', collection.description);
        if (collection.image) {
            formData.append('image', collection.image);
        }

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/collections/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate('/admin/collections');
        } catch (error) {
            console.error('Koleksiyon güncellenirken hata:', error);
        }
    };

    return (
        <div className="add-collection-container">
            <h2>Koleksiyon Düzenle</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Koleksiyon İsmi</label>
                    <input
                        type="text"
                        name="name"
                        value={collection.name}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Açıklama</label>
                    <textarea
                        name="description"
                        value={collection.description}
                        onChange={handleChange}
                        className="form-control"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Mevcut Resim</label>
                    {currentImage && <img src={currentImage} alt="Mevcut resim" className="current-image" />}
                </div>
                <div className="form-group">
                    <label>Yeni Resim (Opsiyonel)</label>
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <button type="submit" className="btn btn-primary">Koleksiyonu Güncelle</button>
            </form>
        </div>
    );
};

export default AdminEditCollection;
