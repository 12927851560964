import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './OrderConfirmation.css';

const OrderConfirmation = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [countdown, setCountdown] = useState(15);
    const navigate = useNavigate();
    const location = useLocation();
    const orderId = location.state?.orderId;

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                console.log('Fetching order details for orderId:', orderId);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Order details received:', response.data);
                setOrderDetails(response.data);
            } catch (error) {
                console.error('Sipariş detayları alınırken hata oluştu:', error);
            }
        };

        if (orderId) {
            fetchOrderDetails();
        } else {
            console.error('orderId bulunamadı');
            navigate('/');
        }

        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate, orderId]);

    useEffect(() => {
        if (countdown === 0) {
            navigate('/');
        }
    }, [countdown, navigate]);

    const handleReturnHome = () => {
        navigate('/');
    };

    if (!orderDetails) {
        return <div className="loading">Sipariş detayları yükleniyor...</div>;
    }

    return (
        <div className="order-confirmation-container">
            <div className="order-confirmation-card">
                <h1 className="order-confirmation-title">Sipariş Başarılı!</h1>
                <div className="order-confirmation-content">
                    <CircleCheckIcon className="success-icon" />
                    <p className="confirmation-message">
                        Satın aldığınız için teşekkür ederiz! Siparişiniz işleme alındı ve yakında kargoya verilecek.
                    </p>
                </div>
                <div className="order-details">
                    <h2>Sipariş Detayları</h2>
                    {orderDetails.items.map((item, index) => (
                        <p key={index} className="order-item">
                            {item.productId.name} <span className="item-quantity">x{item.quantity}</span>
                        </p>
                    ))}
                    <h2>Toplam Tutar</h2>
                    <p className="total-amount">{orderDetails.totalAmount.toFixed(2)} TL</p>
                    <h2>Telefon Numarası</h2>
                    <p className="delivery-address">{orderDetails.phoneNumber}</p>
                </div>
                <p className="redirect-message">Ana sayfaya yönlendiriliyorsunuz... ({countdown} saniye)</p>
                <button className="return-home-button" onClick={handleReturnHome}>Ana Sayfaya Dön</button>
            </div>
        </div>
    );
};

const CircleCheckIcon = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <circle cx="12" cy="12" r="10" />
        <path d="m9 12 2 2 4-4" />
    </svg>
);

export default OrderConfirmation;
