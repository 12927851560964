import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate'i ekledik
import { Container } from 'react-bootstrap'; // React Bootstrap'ten import ettik
import HeartButton from './HeartButton'; // HeartButton'ı ekledik
import './CollectionProducts.css';

const CollectionProducts = () => {
    const { collectionName } = useParams();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate(); // useNavigate'i başlattık

    useEffect(() => {
        fetchCollectionProducts();
    }, [collectionName]);

    const fetchCollectionProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/collection/${collectionName}`);
            setProducts(res.data);
        } catch (error) {
            console.error('Error fetching collection products:', error);
        }
    };

    const handleNavigateToProduct = (productId) => {
        navigate(`/product/${productId}`);
    };

    return (
        <div className="collection-container">
            <Container fluid> {/* Container'ı fluid yaptık */}
                <h2 className="section-title">{collectionName} Koleksiyonu</h2>
                <div className="collection-products-grid">
                    {products.map((product) => (
                        <div key={product._id} className="collection-product-card" onClick={() => handleNavigateToProduct(product._id)}>
                            <div className="collection-product-image-container">
                                <img
                                    src={product.mainImageUrl || 'https://via.placeholder.com/400x400'}
                                    alt={product.name}
                                    className="collection-product-image"
                                />
                                <HeartButton productId={product._id} />
                            </div>
                            <div className="collection-product-content">
                                <h3 className="collection-product-name">{product.name}</h3>
                                <p className="collection-product-description">{product.description}</p>
                                <p className="collection-product-price">${product.price}</p>
                            </div>
                            <button className="add-to-cart-button" onClick={(e) => {
                                e.stopPropagation();
                                // Sepete ekleme işlemi burada yapılacak
                            }}>
                                Sepete Ekle
                            </button>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default CollectionProducts;
