import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SearchOverlay.css';

const SearchOverlay = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`http://localhost:5000/api/search?query=${encodeURIComponent(searchTerm)}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Arama hatası:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
    onClose();
  };

  const handleCollectionClick = (collectionName) => {
    navigate(`/collection/${collectionName}`);
    onClose();
  };

  const handleProductTypeClick = (productType) => {
    navigate(`/products?type=${encodeURIComponent(productType)}`);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="search-overlay">
      <div className="search-overlay-content">
        <button className="close-search" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="search-input-wrapper">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              ref={inputRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Ne arıyorsunuz?"
              className="search-input"
            />
          </div>
        </form>
        {isLoading && <p>Aranıyor...</p>}
        {searchResults && (
          <div className="search-results">
            {searchResults.products.length > 0 && (
              <div>
                <h3>Ürünler</h3>
                <ul>
                  {searchResults.products.map(product => (
                    <li key={product._id} onClick={() => handleProductClick(product._id)}>
                      {product.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {searchResults.collections.length > 0 && (
              <div>
                <h3>Koleksiyonlar</h3>
                <ul>
                  {searchResults.collections.map(collection => (
                    <li key={collection._id} onClick={() => handleCollectionClick(collection.name)}>
                      {collection.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {searchResults.productTypes.length > 0 && (
              <div>
                <h3>Ürün Tipleri</h3>
                <ul>
                  {searchResults.productTypes.map(type => (
                    <li key={type} onClick={() => handleProductTypeClick(type)}>
                      {type}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchOverlay;
