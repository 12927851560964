import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaPlus, FaEye } from 'react-icons/fa';
import './AdminProductList.css';

const AdminProductList = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
            setProducts(res.data);
        } catch (error) {
            console.error('Ürünler yüklenirken hata oluştu:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`);
                setProducts(products.filter(product => product._id !== id));
            } catch (error) {
                console.error('Ürün silinirken hata oluştu:', error);
            }
        }
    };

    const handleAddProduct = () => {
        navigate('/admin/add-product');
    };

    const handleEditProduct = (id) => {
        navigate(`/admin/edit-product/${id}`);
    };

    const handleViewProduct = (id) => {
        navigate(`/product/${id}`);
    };

    return (
        <div className="admin-product-list">
            <div className="header">
                <h2>Ürünler</h2>
                <button className="add-product-btn" onClick={handleAddProduct}>
                    <FaPlus /> Ürün Ekle
                </button>
            </div>
            <div className="product-list-container">
                <table className="product-table">
                    <thead>
                        <tr>
                            <th>Resim</th>
                            <th>Ürün Adı</th>
                            <th>Fiyat</th>
                            <th>Stok</th>
                            <th>İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map(product => (
                            <tr key={product._id}>
                                <td className="product-image-cell">
                                    <img src={product.mainImageUrl} alt={product.name} className="product-image" />
                                </td>
                                <td>{product.name}</td>
                                <td>{product.price} TL</td>
                                <td>{product.stock}</td>
                                <td>
                                    <button onClick={() => handleViewProduct(product._id)} className="action-btn view-btn" aria-label="Görüntüle">
                                        <FaEye />
                                    </button>
                                    <button onClick={() => handleEditProduct(product._id)} className="action-btn edit-btn" aria-label="Düzenle">
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => handleDelete(product._id)} className="action-btn delete-btn" aria-label="Sil">
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="mobile-product-list">
                    {products.map(product => (
                        <div key={product._id} className="mobile-product-card">
                            <img src={product.mainImageUrl} alt={product.name} className="mobile-product-image" />
                            <div className="mobile-product-info">
                                <h3>{product.name}</h3>
                                <p>{product.price} TL</p>
                                <p>Stok: {product.stock}</p>
                            </div>
                            <div className="mobile-product-actions">
                                <button onClick={() => handleViewProduct(product._id)} className="action-btn view-btn" aria-label="Görüntüle">
                                    <FaEye />
                                </button>
                                <button onClick={() => handleEditProduct(product._id)} className="action-btn edit-btn" aria-label="Düzenle">
                                    <FaEdit />
                                </button>
                                <button onClick={() => handleDelete(product._id)} className="action-btn delete-btn" aria-label="Sil">
                                    <FaTrash />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AdminProductList;
