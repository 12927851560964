import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './SimilarProducts.css';

const SimilarProducts = ({ currentProductId, currentProductName, currentProductDescription }) => {
    const [similarProducts, setSimilarProducts] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        const fetchSimilarProducts = async () => {
            try {
                if (!currentProductId || !currentProductName || !currentProductDescription) {
                    console.error('Missing required parameters for fetching similar products');
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/similar`, {
                    params: {
                        id: currentProductId,
                        name: currentProductName,
                        description: currentProductDescription
                    }
                });
                setSimilarProducts(response.data.slice(0, 4));
            } catch (error) {
                console.error('Error fetching similar products:', error);
                if (error.response) {
                    console.error('Error response:', error.response.data);
                }
                setSimilarProducts([]);
            }
        };

        fetchSimilarProducts();
    }, [currentProductId, currentProductName, currentProductDescription]);

    useEffect(() => {
        if (gridRef.current) {
            const scrollWidth = gridRef.current.scrollWidth;
            const clientWidth = gridRef.current.clientWidth;
            gridRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
        }
    }, [similarProducts]);

    if (similarProducts.length === 0) return null;

    return (
        <div className="similar-products">
            <h2>Benzer Ürünler</h2>
            <div className="similar-products-container">
                <div className="similar-products-grid" ref={gridRef}>
                    {similarProducts.map(product => (
                        <Link to={`/product/${product._id}`} key={product._id} className="similar-product-card">
                            <img src={product.mainImageUrl} alt={product.name} />
                            <h3>{product.name}</h3>
                            <p>{product.price.toFixed(2)} TL</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SimilarProducts;