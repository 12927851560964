import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './CategoryCard.css';

const CategoryCard = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            const categoriesWithProducts = await Promise.all(
                res.data.map(async (category) => {
                    const productRes = await axios.get(`${process.env.REACT_APP_API_URL}/products/collection/${category.name}`);
                    return { ...category, products: productRes.data.slice(0, 2) }; // Get the first 2 products
                })
            );
            setCategories(categoriesWithProducts);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleNavigateToProduct = (productId) => {
        navigate(`/product/${productId}`);
    };

    const handleNavigateToCollection = (categoryName) => {
        navigate(`/collections/${categoryName}`); // Navigate to the collection page
    };

    return (
        <Container className="category-section">
            {categories.map((category, index) => (
                <div key={index} className={`category-card ${index % 2 === 0 ? 'left' : 'right'}`}>
                    <div 
                        className="category-image-wrapper" 
                        onClick={() => handleNavigateToCollection(category.name)} // Navigate when clicking the image
                        style={{ cursor: 'pointer' }} // Add pointer cursor to indicate it's clickable
                    >
                        <img
                            src={category.imageUrl || 'https://via.placeholder.com/600x400'}
                            alt={category.name}
                            className="category-image"
                        />
                    </div>
                    <div className="category-content">
                        <h3 className="category-name">{category.name}</h3>
                        <p className="category-description">{category.description || 'Koleksiyon açıklaması'}</p>

                        {/* Product cards */}
                        <div className="small-products-preview">
                            {category.products.map((product) => (
                                <div key={product._id} className="small-product-thumbnail" onClick={() => handleNavigateToProduct(product._id)}>
                                    <div className="product-thumbnail-image-wrapper">
                                        <img
                                            src={product.mainImageUrl || 'https://via.placeholder.com/100x100'}
                                            alt={product.name}
                                            className="small-thumbnail-image"
                                        />
                                    </div>
                                    <div className="product-thumbnail-details">
                                        <p className="product-price">
                                            {product.price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </Container>
    );
};

export default CategoryCard;
