import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                if (response.data.isAdmin) {
                    localStorage.setItem('isAdmin', 'true');
                } else {
                    localStorage.setItem('isAdmin', 'false');
                }
                
                // Ana sayfaya yönlendir
                navigate('/');
    
                // Sayfayı yenile
                window.location.reload();
            } else {
                console.error("No token found in response");
                alert("Giriş başarısız. Lütfen bilgilerinizi kontrol edin.");
            }
        } catch (err) {
            console.error('Login error:', err);
            if (err.response) {
                console.error('Error response:', err.response.data);
                alert(`Giriş başarısız: ${err.response.data.message || 'Bilinmeyen bir hata oluştu'}`);
            } else if (err.request) {
                console.error('No response received:', err.request);
                alert('Sunucuya bağlanılamadı. Lütfen internet bağlantınızı kontrol edin.');
            } else {
                console.error('Error setting up request:', err.message);
                alert('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
            }
        }
    };
    
    

    return (
        <div className="auth-container">
            <h2>Giriş Yap</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Email adresi</label>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Şifre</label>
                    <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="auth-button">Giriş Yap</button>
            </form>
            <p className="auth-link">
                Hesabınız yok mu? <Link to="/signup">Hemen üye olun</Link>
            </p>
        </div>
    );
}
