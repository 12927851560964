import React from 'react';
import { Container, Grid, Typography, IconButton, TextField, Button } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className="footer-title">Hakkımızda</Typography>
                        <Typography variant="body2">
                        Demmur: Gerçek Deri, Modern Tasarım.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className="footer-title">Hızlı Bağlantılar</Typography>
                        <ul className="footer-links">
                            <li><a href="/about">Hakkımızda</a></li>
                            <li><a href="/products">Ürünler</a></li>
                            <li><a href="/contact">İletişim</a></li>
                            <li><a href="/faq">SSS</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className="footer-title">Bültenimize Abone Olun</Typography>
                        <form className="newsletter-form">
                            <TextField
                                variant="outlined"
                                placeholder="E-posta adresiniz"
                                fullWidth
                                margin="dense"
                            />
                            <Button variant="contained" color="primary" fullWidth style={{backgroundColor: '#8B0000', color: '#ffffff'}}>
                                Abone Ol
                            </Button>
                        </form>
                    </Grid>
                </Grid>
                <div className="footer-bottom">
                    <Typography variant="body2" className="copyright">
                        © 2024 YazCode Tarafından Tasarlanmıştır. Tüm hakları saklıdır.
                    </Typography>
                    <div className="social-icons">
                        <IconButton aria-label="Facebook" color="inherit">
                            <Facebook />
                        </IconButton>
                        <IconButton aria-label="Twitter" color="inherit">
                            <Twitter />
                        </IconButton>
                        <IconButton aria-label="Instagram" color="inherit">
                            <Instagram />
                        </IconButton>
                        <IconButton aria-label="LinkedIn" color="inherit">
                            <LinkedIn />
                        </IconButton>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
