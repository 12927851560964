import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import './AdminBannerList.css';
import { useNavigate } from 'react-router-dom';

const AdminBannerList = () => {
    const [banners, setBanners] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/banners`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setBanners(res.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            await axios.delete(`${process.env.REACT_APP_API_URL}/banners/${id}`, config);
            setBanners(banners.filter((banner) => banner._id !== id));
        } catch (error) {
            console.error('Error deleting banner:', error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/admin/edit-banner/${id}`);
    };

    return (
        <div className="banner-list-container">
            <h2>Tüm Bannerlar</h2>
            {banners.length === 0 ? (
                <p>Hiç banner eklenmedi.</p>
            ) : (
                <table className="banner-table">
                    <thead>
                        <tr>
                            <th>Resim</th>
                            <th>Başlık</th>
                            <th>Açıklama</th>
                            <th>İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {banners.map((banner) => (
                            <tr key={banner._id}>
                                <td>
                                    <img src={banner.imageUrl} alt={banner.title} className="banner-image" />
                                </td>
                                <td>{banner.title}</td>
                                <td>{banner.description}</td>
                                <td className="banner-actions">
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="icon edit-banner-btn"
                                        onClick={() => handleEdit(banner._id)}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="icon delete-banner-btn"
                                        onClick={() => handleDelete(banner._id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminBannerList;
