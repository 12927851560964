import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Doğru import


const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [isAuth, setAuth] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [userId, setUserId] = useState(null);
    const [cartCount, setCartCount] = useState(0);
    const [favoritesCount, setFavoritesCount] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setAuth(true);
                setAdmin(decodedToken.isAdmin);
                setUserId(decodedToken.userId);
            } catch (error) {
                console.error('Token decode error:', error);
            }
        }

        // Favori ürünleri localStorage ya da API'den alın
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        setFavoritesCount(favorites.length);
    }, []);

    return (
        <AppContext.Provider value={{ isAuth, setAuth, isAdmin, setAdmin, userId, setUserId, cartCount, setCartCount, favoritesCount }}>
            {children}
        </AppContext.Provider>
    );
};
