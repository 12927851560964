import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', true);
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-banner">
                <p>Bu site, size daha iyi bir deneyim sunmak için çerezler kullanır. Kabul ediyor musunuz?</p>
                <button onClick={handleAccept}>Kabul Et</button>
            </div>
        )
    );
};

export default CookieBanner;
