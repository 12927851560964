import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import './AdminProducts.css';

const shoeSizes = [
    { value: '35', label: '35' },
    { value: '35.5', label: '35.5' },
    { value: '36', label: '36' },
    { value: '36.5', label: '36.5' },
    { value: '37', label: '37' },
    { value: '37.5', label: '37.5' },
    { value: '38', label: '38' },
    { value: '38.5', label: '38.5' },
    { value: '39', label: '39' },
    { value: '39.5', label: '39.5' },
    { value: '40', label: '40' },
    { value: '40.5', label: '40.5' },
    { value: '41', label: '41' },
    { value: '41.5', label: '41.5' },
    { value: '42', label: '42' },
    { value: '42.5', label: '42.5' },
    { value: '43', label: '43' }
];

const AddProduct = () => {
    const [product, setProduct] = useState({
        name: '',
        price: '',
        description: '',
        stock: '',
        images: [],
        collection: '',
        sizes: [],
        colors: [], // Yeni renk seçeneği ekleme
        mainImageIndex: 0
    });
    const [collections, setCollections] = useState([]);
    const [newColorInput, setNewColorInput] = useState(''); // Yeni renk girişi için state
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchProduct();
        }
        fetchCollections();
    }, [id]);

    const fetchProduct = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
            const { name, price, description, stock, imageUrls, collection, sizes, colors, mainImageUrl } = res.data;
            setProduct({
                name,
                price,
                description,
                stock,
                images: imageUrls,
                collection,
                sizes: sizes.map(size => ({ value: size, label: size })),
                colors: colors.map(color => ({ value: color, label: color })), // Renkleri set et
                mainImageIndex: imageUrls.indexOf(mainImageUrl)
            });
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };
    const fetchCollections = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCollections(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleChange = (e) => {
        if (e.target.name === 'images') {
            const files = Array.from(e.target.files);
            setProduct(prevProduct => ({
                ...prevProduct,
                images: [...prevProduct.images, ...files]
            }));
        } else if (e.target.name === 'price' || e.target.name === 'stock') {
            setProduct({ ...product, [e.target.name]: Number(e.target.value) });
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }
    };

    const handleSizeChange = (selectedOptions) => {
        setProduct({ ...product, sizes: selectedOptions });
    };

    const handleColorChange = (selectedOptions) => {
        setProduct({ ...product, colors: selectedOptions });
    };

    const handleImageClick = (index) => {
        setProduct({ ...product, mainImageIndex: index });
    };

    const handleNewColorInput = (e) => {
        const value = e.target.value;
        if (value.includes(',')) {
            const newColors = value.split(',').map(color => color.trim()).filter(color => color);
            if (newColors.length > 0) {
                setProduct(prev => ({
                    ...prev,
                    colors: [
                        ...prev.colors,
                        ...newColors.map(color => ({ value: color, label: color }))
                    ]
                }));
                setNewColorInput(''); // Giriş kutusunu temizle
            }
        } else {
            setNewColorInput(value);
        }
    };

    const handleRemoveImage = (index) => {
        setProduct(prevProduct => ({
            ...prevProduct,
            images: prevProduct.images.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', product.name);
        formData.append('price', product.price);
        formData.append('description', product.description);
        formData.append('stock', product.stock);
        formData.append('collection', product.collection); // Koleksiyon ID'si
        formData.append('sizes', JSON.stringify(product.sizes.map(size => size.value)));
        formData.append('colors', JSON.stringify(product.colors.map(color => color.value)));
        formData.append('mainImageIndex', product.mainImageIndex);
    
        product.images.forEach((image, index) => {
            if (image instanceof File) {
                formData.append('images', image);
            } else {
                formData.append('existingImages', image);
            }
        });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/products`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Product added:', response.data);
            navigate('/admin/products');
        } catch (error) {
            console.error('Ürün eklenirken hata:', error.response ? error.response.data : error.message);
        }
    };
    
    return (
        <div className="create-product-container">
            <h2>{id ? 'Ürünü Düzenle' : 'Yeni Ürün Ekle'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="name">Ürün İsmi</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={product.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="price">Fiyat (TL)</label>
                        <input
                            type="number"
                            id="price"
                            name="price"
                            value={product.price}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Açıklama</label>
                    <textarea
                        id="description"
                        name="description"
                        value={product.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="stock">Stok</label>
                        <input
                            type="number"
                            id="stock"
                            name="stock"
                            value={product.stock}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="collection">Koleksiyon</label>
                        <select
                            id="collection"
                            name="collection"
                            value={product.collection} // Koleksiyon ID'si
                            onChange={handleChange}
                            required
                        >
                            <option value="">Koleksiyon Seçin</option>
                            {collections.map((collection) => (
                                <option key={collection._id} value={collection._id}>{collection.name}</option> // Koleksiyon ID'si kullanılıyor
                            ))}
                        </select>
                    </div>

                </div>
                <div className="form-group">
                    <label>Boyutlar</label>
                    <Select
                        isMulti
                        name="sizes"
                        options={shoeSizes}
                        value={product.sizes}
                        onChange={handleSizeChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <div className="form-group">
                    <label>Renkler</label>
                    <Select
                        isMulti
                        name="colors"
                        options={product.colors}
                        value={product.colors}
                        onChange={handleColorChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                    <div className="new-color-input">
                        <input
                            type="text"
                            value={newColorInput}
                            onChange={handleNewColorInput}
                            placeholder="Yeni renk ekleyin ve Enter'a basın"
                        />
                        <button type="button" onClick={() => handleNewColorInput({ target: { value: newColorInput + ',' } })}>
                            <FaPlus />
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label>Mevcut Resimler</label>
                    <div className="image-preview-container">
                        {product.images.map((image, index) => (
                            <div key={index} className="image-preview-wrapper">
                                <img
                                    src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                                    alt="current"
                                    className={`current-image ${product.mainImageIndex === index ? 'main-image' : ''}`}
                                    onClick={() => handleImageClick(index)}
                                />
                                <button 
                                    type="button" 
                                    className="remove-image-btn" 
                                    onClick={() => handleRemoveImage(index)}
                                >
                                    <FaTimes />
                                </button>
                                {product.mainImageIndex === index && <span className="main-image-label">Ana Resim</span>}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="new-images">Yeni Resimler</label>
                    <input
                        type="file"
                        id="new-images"
                        name="images"
                        onChange={handleChange}
                        multiple
                    />
                </div>
                <button type="submit" className="btn btn-primary">{id ? 'Güncelle' : 'Ekle'}</button>
            </form>
        </div>
    );
};

export default AddProduct;
