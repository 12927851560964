import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminOrderList.css';

const AdminOrderList = () => {
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in.');
                return;
            }

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setOrders(res.data);
        } catch (error) {
            console.error('Siparişler alınırken hata:', error);
        }
    };

    const handleRowClick = (orderId) => {
        console.log('Navigating to order details with ID:', orderId); // Bu log ekleniyor
        navigate(`/admin/orders/${orderId}`);
    };

    return (
        <div className="admin-order-list">
            <h2>Siparişler</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Telefon Numarası</th>
                        <th>Ürünler</th>
                        <th>Tarih</th>
                        <th>Durum</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order._id} onClick={() => handleRowClick(order._id)}>
                            <td>{order._id}</td>
                            <td>{order.phoneNumber}</td>
                            <td>{order.items.map(item => item.productId && item.productId.name ? item.productId.name : 'Ürün bilgisi yok').join(', ')}</td>
                            <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                            <td>{order.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminOrderList;
