import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart, faShoppingBag, faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';
import logo from './logo.png';
import SearchOverlay from './SearchOverlay';
import { useAppContext } from '../contexts/AppContext';

const Navbar = ({ isAuth, isAdmin, setAuth }) => {
  const { cartCount } = useAppContext();
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Favori sayısını güncelleme fonksiyonu
  const handleFavoritesChange = () => {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavoritesCount(favorites.length);
  };

  useEffect(() => {
    handleFavoritesChange();
    window.addEventListener('storage', handleFavoritesChange);

    return () => {
      window.removeEventListener('storage', handleFavoritesChange);
    };
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    navigate('/');
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsSearchOpen(true);
  };

  const handleAccountClick = () => {
    if (isAuth) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="top-info-bar">
        <span>Ücretsiz kargo (1500 TL üzeri) - Ücretsiz İade!</span>
      </div>
      <header className="main-navbar">
        <div className="navbar-content">
          <div className="navbar-left">
            <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            <Link to="/arama" className="mobile-search-icon">
              <FontAwesomeIcon icon={faSearch} />
            </Link>
            <nav className="nav-items">
              <div className="dropdown" ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span className="dropdown-toggle">FİLTRE</span>
                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                  <div className="dropdown-column">
                    <h3>Ayakkabılar</h3>
                    <Link to="/kadin/tum-ayakkabilar">Tüm Ayakkabılar</Link>
                    <Link to="/kadin/yeni-gelenler">Yeni Gelenler</Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <Link to="/" className="logo">
            <img src={logo} alt="ALDO" />
          </Link>
          <div className="navbar-right">
            <div className="icon-link desktop-only" onClick={handleAccountClick}>
              <FontAwesomeIcon icon={faUser} />
              <span>HESABIM</span>
            </div>
            <Link to="/arama" className="icon-link desktop-only" onClick={handleSearchClick}>
              <FontAwesomeIcon icon={faSearch} />
              <span>ARAMA</span>
            </Link>
            <Link to="/favorites" className="icon-link">
              <FontAwesomeIcon icon={faHeart} />
              <span>{favoritesCount}</span>
            </Link>
            <Link to="/cart" className="icon-link">
              <FontAwesomeIcon icon={faShoppingBag} />
              <span>{cartCount} ürün</span>
            </Link>
            {isAdmin && (
              <Link to="/admin" className="admin-button">
                Admin
              </Link>
            )}
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu">
          <button className="close-menu" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <nav>
            <ul>
              <li>
                <Link to="/" onClick={toggleMobileMenu}>Ana Sayfa</Link>
              </li>
              <li>
                <Link to="/kadin/tum-ayakkabilar" onClick={toggleMobileMenu}>Kadın</Link>
              </li>
              <li>
                <Link to="/erkek/tum-ayakkabilar" onClick={toggleMobileMenu}>Erkek</Link>
              </li>
              <li>
                <Link to="/canta" onClick={toggleMobileMenu}>Çanta</Link>
              </li>
              <li>
                <Link to="/aksesuar" onClick={toggleMobileMenu}>Aksesuar</Link>
              </li>
              {isAuth ? (
                <li>
                  <button onClick={handleLogout} className="logout-btn">Çıkış Yap</button>
                </li>
              ) : (
                <li>
                  <Link to="/login" onClick={toggleMobileMenu}>Giriş Yap / Üye Ol</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      )}

      <SearchOverlay isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </>
  );
};

export default Navbar;
