import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AdminOrderDetails.css';

const AdminOrderDetails = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in.');
                return;
            }
    
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            setOrder(res.data);
        } catch (error) {
            console.error('Error fetching order details:', error.response ? error.response.data : error.message);
        }
    };
    

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <div className="order-details-container">
            <h2>Sipariş Detayları</h2>
            <div className="order-details">
                <p><strong>ID:</strong> {order._id}</p>
                <p><strong>Telefon Numarası:</strong> {order.phoneNumber}</p>
                <p><strong>Sipariş Tarihi:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                <p><strong>Toplam Tutar:</strong> ${order.totalAmount}</p>
                <p><strong>Durum:</strong> {order.status}</p>

                <h3>Ürünler</h3>
                {order.items.map((item, index) => (
                    <div key={index} className="order-item">
                        <img 
                            src={item.productId.mainImageUrl} 
                            alt={item.productId.name} 
                            className="order-item-image" 
                        />
                        <div className="order-item-details">
                            <p><strong>Ürün Adı:</strong> {item.productId.name}</p>
                            <p><strong>Renk:</strong> {item.productId.color}</p>
                            <p><strong>Beden:</strong> {item.size}</p>
                            <p><strong>Adet:</strong> {item.quantity}</p>
                            <p><strong>Fiyat:</strong> ${item.productId.price}</p>
                            <p><strong>Toplam Fiyat:</strong> ${item.productId.price * item.quantity}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminOrderDetails;
