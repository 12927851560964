import React, { useState, useEffect } from 'react';
import 'antd/dist/reset.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Doğru import
import AdminBannerList from './components/AdminBannerList'; // Import the component
import AdminEditBanner from './components/AdminEditBanner';

import NavigationBar from './components/Navbar';
import CustomCarousel from './components/Carousel';
import CategoryCard from './components/CategoryCard';
import ProductList from './components/ProductList';
import Footer from './components/Footer';
import AdminLayout from './components/AdminLayout';
import AdminProductList from './components/AdminProductList';
import AdminProducts from './components/AdminProducts';
import AdminCustomerList from './components/AdminCustomerList';
import AdminCollectionList from './components/AdminCollectionList';
import AdminAddCollection from './components/AdminAddCollection';
import AdminOrderList from './components/AdminOrderList';
import UserProfile from './components/UserProfile';
import AdminAddBanner from './components/AdminAddBanner';
import CollectionProducts from './components/CollectionProducts';
import CookieBanner from './components/CookieBanner';
import ProductDetail from './components/ProductDetail';
import LoginForm from './components/Login';
import SignupForm from './components/SignupForm';
import Dashboard from './components/Dashboard';
import Cart from './components/Cart';
import FavoritesPage from './components/FavoritesPage'; // Yolu düzelttik
import './App.css';
import AdminOrderDetails from './components/AdminOrderDetails';
import { AppProvider } from './contexts/AppContext';
import AdminEditCollection from './components/AdminEditCollection';
import AdminEditProduct from './components/AdminEditProduct';
import OrderConfirmation from './components/OrderConfirmation';

function App() {
    const [isAuth, setAuth] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token !== 'undefined') {
            try {
                const decoded = jwtDecode(token);
                if (Date.now() >= decoded.exp * 1000) {
                    console.error('Token expired.');
                    setAuth(false);
                    setAdmin(false);
                    setUserId(null);
                    localStorage.removeItem('token');
                } else {
                    setAuth(true);
                    setUserId(decoded.id);
                    setAdmin(decoded.isAdmin ?? false);
                }
            } catch (error) {
                console.error('Token çözümleme hatası:', error);
                setAuth(false);
                setAdmin(false);
                setUserId(null);
                localStorage.removeItem('token');
            }
        } else {
            setAuth(false);
            setAdmin(false);
            setUserId(null);
        }
    }, []);

    return (
        <AppProvider>
            <Router>
                <div id="root" className="d-flex flex-column min-vh-100">
                    <NavigationBar isAuth={isAuth} isAdmin={isAdmin} setAuth={setAuth} />
                    <CookieBanner />
                    <main className="flex-grow-1">
                        <Routes>
                            <Route path="/" element={
                                <>
                                    <CustomCarousel />
                                    <CategoryCard />
                                    <ProductList />
                                    
                                </>
                            } />
                            
                            <Route path="/collections/:collectionName" element={<CollectionProducts />} />
                            <Route path="/cart" element={<Cart />} />
                            <Route path="/favorites" element={<FavoritesPage />} />
                            <Route path="/product/:id" element={<ProductDetail />} />
                            <Route path="/profile" element={
                                <UserProfile 
                                    setAuth={setAuth} 
                                    setAdmin={setAdmin} 
                                />
                            } />
                            <Route path="/admin/*" element={<AdminLayout />}>
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="products" element={<AdminProductList />} />
                                <Route path="add-product" element={<AdminProducts />} />
                                <Route path="customers" element={<AdminCustomerList />} />
                                <Route path="collections" element={<AdminCollectionList />} />
                                <Route path="add-collection" element={<AdminAddCollection />} />
                                <Route path="orders" element={<AdminOrderList />} />
                                <Route path="orders/:orderId" element={<AdminOrderDetails />} />
                                <Route path="add-banner" element={<AdminAddBanner />} />
                                <Route path="banners" element={<AdminBannerList />} />
                                <Route path="edit-banner/:id" element={<AdminEditBanner />} />


                                <Route path="edit-collection/:id" element={<AdminEditCollection />} />
                                <Route path="edit-product/:id" element={<AdminEditProduct />} />
                            </Route>

                            <Route path="/login" element={<LoginForm setAuth={setAuth} setAdmin={setAdmin} />} />
                            <Route path="/signup" element={<SignupForm setAuth={setAuth} setAdmin={setAdmin} />} />
                            <Route path="/order-confirmation" element={<OrderConfirmation />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
        </AppProvider>
    );
}

export default App;
