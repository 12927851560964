import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';

const AdminLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="admin-layout">
            <AdminSidebar collapsed={collapsed} setCollapsed={setCollapsed} isMobile={isMobile} />
            <main className="admin-content" style={{ 
                marginLeft: isMobile ? '0' : (collapsed ? '60px' : '250px'),
                width: isMobile ? '100%' : `calc(100% - ${collapsed ? '60px' : '250px'})`,
                transition: 'all 0.3s ease'
            }}>
                <Outlet />
            </main>
        </div>
    );
};

export default AdminLayout;