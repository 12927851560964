import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './AdminEditBanner.css';

const AdminEditBanner = () => {
    const { id } = useParams(); // Get banner ID from URL params
    const navigate = useNavigate();
    const [banner, setBanner] = useState({
        title: '',
        description: '',
        image: null, // To handle image replacement
    });
    const [existingImageUrl, setExistingImageUrl] = useState(''); // Store the current image URL

    useEffect(() => {
        fetchBannerDetails();
    }, []);

    // Fetch the existing banner details
    const fetchBannerDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/banners/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const bannerData = res.data;
            setBanner({
                title: bannerData.title,
                description: bannerData.description,
                image: null, // Initially no new image selected
            });
            setExistingImageUrl(bannerData.imageUrl); // Store the current image URL
        } catch (error) {
            console.error('Error fetching banner details:', error);
        }
    };

    // Handle input changes
    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setBanner({ ...banner, image: e.target.files[0] });
        } else {
            setBanner({ ...banner, [e.target.name]: e.target.value });
        }
    };

    // Handle form submission for updating the banner
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };
            const formData = new FormData();
            formData.append('title', banner.title);
            formData.append('description', banner.description);
            
            // If a new image is selected, append it to the formData
            if (banner.image) {
                formData.append('image', banner.image);
            }

            // Make PUT request to update the banner
            await axios.put(`${process.env.REACT_APP_API_URL}/banners/${id}`, formData, config);
            navigate('/admin/banners'); // Navigate back to banners list after success
        } catch (error) {
            console.error('Error updating banner:', error);
        }
    };

    return (
        <div className="edit-banner-container">
            <h2>Banner Düzenle</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Başlık</label>
                    <input
                        type="text"
                        name="title"
                        value={banner.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Açıklama</label>
                    <textarea
                        name="description"
                        value={banner.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Mevcut Resim</label>
                    {existingImageUrl && (
                        <div className="image-preview">
                            <img src={existingImageUrl} alt="Banner" className="banner-image" />
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>Yeni Resim Seç (Değiştirmek İstiyorsanız)</label>
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn">Güncelle</button>
            </form>
        </div>
    );
};

export default AdminEditBanner;
