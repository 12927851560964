import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import './AdminCollectionList.css';

const AdminCollectionList = () => {
    const [collections, setCollections] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCollections(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/collections/${id}`);
            setCollections(collections.filter(collection => collection._id !== id));
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };

    const handleAddCollection = () => {
        navigate('/admin/add-collection');
    };

    const handleEditCollection = (id) => {
        navigate(`/admin/edit-collection/${id}`);
    };

    return (
        <div className="collection-list-container">
            <div className="header">
                <h2>Koleksiyonlar</h2>
                <button className="add-collection-btn" onClick={handleAddCollection}>Koleksiyon Ekle</button>
            </div>
            <div className="collection-grid">
                <table className="collection-table">
                    <thead>
                        <tr>
                            <th>Resim</th>
                            <th>Koleksiyon Adı</th>
                            <th>İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {collections.map(collection => (
                            <tr key={collection._id} className="table-row">
                                <td><img src={collection.imageUrl} alt={collection.name} className="collection-image" /></td>
                                <td>{collection.name}</td>
                                <td className="collection-actions">
                                    <FontAwesomeIcon 
                                        icon={faEdit} 
                                        className="icon edit-collection-btn" 
                                        onClick={() => handleEditCollection(collection._id)} 
                                    />
                                    <FontAwesomeIcon 
                                        icon={faTrash} 
                                        className="icon delete-collection-btn" 
                                        onClick={() => handleDelete(collection._id)} 
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminCollectionList;
