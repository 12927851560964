import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from 'axios';
import './ProductCard.css';
import { useAppContext } from '../contexts/AppContext'; // Contexti import edin

const ProductCard = ({ product, onFavoriteChange }) => {
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(false);
    const { setFavoritesCount } = useAppContext(); // Favori sayısını güncellemek için context'ten alın

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('Token bulunamadı, lütfen giriş yapın.');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites/check/${product._id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Favori durumu yanıtı:', response.data);
                setIsFavorite(response.data.isFavorite);
            } catch (error) {
                console.error('Favori durumu kontrol edilirken hata:', error);
            }
        };
        
        checkFavoriteStatus();
    }, [product._id]);

    const handleProductClick = () => {
        navigate(`/product/${product._id}`);
    };

    const handleFavoriteClick = async (e) => {
        e.stopPropagation();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token bulunamadı, lütfen giriş yapın.');
                return;
            }
            const url = `${process.env.REACT_APP_API_URL}/favorites/${isFavorite ? 'remove' : 'add'}`;
            const method = isFavorite ? 'delete' : 'post';
            const response = await axios({
                method,
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: { productId: product._id }
            });

            if (response.status === 200) {
                setIsFavorite(!isFavorite);
                
                // Favori listesi güncelleniyor
                updateFavorites(product, !isFavorite);
                
                if (onFavoriteChange) {
                    onFavoriteChange(product._id, !isFavorite);
                }
            } else {
                throw new Error('Favori işlemi gerçekleştirilemedi');
            }
        } catch (error) {
            console.error('Favori işlemi sırasında bir hata oluştu:', error);
        }
    };

    const updateFavorites = (product, isAdding) => {
        const currentFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        let updatedFavorites;

        if (isAdding) {
            updatedFavorites = [...currentFavorites, product];
        } else {
            updatedFavorites = currentFavorites.filter(fav => fav._id !== product._id);
        }
        
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));

        // Favori sayısını güncelle
        setFavoritesCount(updatedFavorites.length);
    };

    return (
        <Box
            onClick={handleProductClick}
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover img': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out'
                },
                '&:hover .product-info': {
                    transform: 'translateY(0)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                }
            }}
        >
            <img
                src={product.mainImageUrl || 'https://via.placeholder.com/300x400'}
                alt={product.name}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out'
                }}
            />
            <Box
                className="product-info"
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    transform: 'translateY(50%)',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: 'transparent',
                }}
            >
                <Typography
                    level="body-lg"
                    sx={{ color: '#8b0000', fontWeight: 'bold', textAlign: 'center', padding: '5px' }}
                >
                    {product.price ? `${product.price.toFixed(2)} ₺` : 'Fiyat bilgisi mevcut değil'}
                </Typography>
                <Typography
                    level="body-lg"
                    sx={{ color: '#8b0000', fontWeight: 'bold', textAlign: 'center', padding: '5px' }}
                >
                    {product.name}
                </Typography>
            </Box>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    handleFavoriteClick(e);
                }}
                aria-label={isFavorite ? "Favorilerden çıkar" : "Favorilere ekle"}
                variant="plain"
                color={isFavorite ? "danger" : "neutral"}
                size="sm"
                sx={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                    zIndex: 2,
                    borderRadius: '50%',
                    backgroundColor: isFavorite ? 'rgba(255,0,0,0.1)' : 'rgba(255,255,255,0.4)',
                    '&:hover': { backgroundColor: isFavorite ? 'rgba(255,0,0,0.2)' : 'rgba(255,255,255,0.8)' },
                }}
            >
                <FavoriteIcon />
            </IconButton>
        </Box>
    );
};

export default ProductCard;
