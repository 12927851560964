import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import ProductCard from './ProductCard';

const ProductList = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
                setProducts(res.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, []);

    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: '20px' }}>
            <Typography
                level="h2"
                sx={{ textAlign: 'center', marginBottom: '2rem', fontSize: '2rem' }}
            >
                Ürünlerimiz
            </Typography>
            <Grid container spacing={2.5} sx={{ width: '100%', margin: 0 }}>
                {products.map((product) => (
                    <Grid key={product._id} xs={12} sm={6} md={4} sx={{ padding: '10px' }}>
                        <Box sx={{ height: 0, paddingTop: '133.33%', position: 'relative' }}>
                            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                                <ProductCard product={product} />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ProductList;
